



























import Vue, { VueConstructor } from 'vue';
import AdminModuleSubscriptionService from '@/services/admin/AdminModuleSubscriptionService';
import { MetaInfo } from 'vue-meta';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ToastMessage from '@/models/ToastMessage';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import { format } from 'date-fns';

const validations = {
  moduleSubscription: {
    endedAt: {},
    startedAt: { required },
  },
};

class ModuleSubscriptionEditDto {
  id!: string;
  module!: string;
  endedAt!: string;
  startedAt!: string;
}

export default (Vue as VueConstructor<Vue>).extend({
  components: { ValidatedInput },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminModuleSubscriptionEdit.meta.title').toString(),
    };
  },
  props: {
    moduleSubscriptionId: {
      required: true,
      type: String,
    },
  },
  validations,
  mixins: [validationMixin],
  data() {
    return {
      moduleSubscription: new ModuleSubscriptionEditDto(),
      loading: false,
      errorMessage: '',
    };
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminModuleSubscriptionEdit.breadcrumb.last' },
      ]);
    },

    handleCancel() {
      this.$router.push({ name: 'AdminModuleSubscriptionsIndex' });
    },

    loadData() {
      this.loading = true;
      AdminModuleSubscriptionService.getEdit(this.moduleSubscriptionId).then((res) => {
        this.loading = false;
        this.moduleSubscription = res.value;
        this.moduleSubscription.startedAt = format(new Date(res.value.startedAt), 'yyyy-MM-dd');
        this.moduleSubscription.endedAt = format(new Date(res.value.endedAt), 'yyyy-MM-dd');
        this.setBreadCrumb();
      });
    },

    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorMessage = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        return AdminModuleSubscriptionService.update({ ...this.moduleSubscription }).then(
          () => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
            );
            this.$router.push({ name: 'AdminModuleSubscriptionsIndex' });
          },
          (error) => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
            );
            this.loading = false;
            this.errorMessage = error;
          }
        );
      }
    },
  },
});
